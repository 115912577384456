import * as React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import HeaderImage from '../../images/nesisai-header-image.svg'

const Item = styled(Box)(({ theme }) => ({
    padding: theme.spacing(3),
    width: "300px",
    textAlign: 'left',
    flexGrow: 1
  }));


export default function HeaderGrid() {
  return (
    <Box>
      <Stack
        spacing={{ xs: 1, sm: 2 }}
        direction="row"
        useFlexGap
        sx={{ flexWrap: 'wrap', backgroundColor:"#f2eff9" }}
        
      >
        <Item>
            <Typography variant="h3" gutterBottom>
              <strong>AI Powered Enterprise Knowledge Discovery</strong>
            </Typography>
            <Typography variant="h4" gutterBottom>
            Interact with your private data and document repositories to uncover hidden knowledge and insights.
            </Typography>
        </Item>
        <Item>
            <HeaderImage />
        </Item>
      </Stack>
    </Box>
  );
}
